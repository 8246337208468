import App from "./App";
import ReactDOM from "react-dom/client";
import "./assets/scss/index.scss";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./store/auth-contex";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <AuthContextProvider>
            <App />
        </AuthContextProvider>
    </BrowserRouter>
);
